var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"paragraph-history"},[_c('b-table',{attrs:{"items":[_vm.datas],"fields":_vm.fields,"striped":"","hover":"","responsive":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('span',{staticClass:"text-md text-primary"},[_vm._v(" "+_vm._s((data.index +1)))])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active_history.status.name)+" "),_c('br'),(item.active_history.status_title)?_c('span',[_vm._v(" "+_vm._s(item.active_history.status_title)+" ")]):_vm._e()]}},{key:"cell(ministry_name)",fn:function(ref){
var item = ref.item;
return [(item.active_history.management)?_c('span',[_vm._v(" "+_vm._s(item.active_history.management_type.name)+" "),_c('br'),_vm._v(" "+_vm._s(item.active_history.management.name)+", "),(item.active_history.management.user)?_c('span',[_vm._v(" "+_vm._s(item.active_history.management.user.lastname)+" "+_vm._s(item.active_history.management.user.firstname)+" ")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(item.active_history.ministry_name)+" ")])]}},{key:"cell(deadline)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active_history.deadline)+" ")]}}])}),_c('div',{staticClass:"my-4"},[_c('h3',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('History'))+" ")])])]),_c('b-table',{attrs:{"items":_vm.datas.histories,"fields":_vm.hisotryItems,"striped":"","hover":"","responsive":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('span',{staticClass:"text-md text-primary"},[_vm._v(" "+_vm._s((data.index +1)))])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.name)+" "),_c('br'),(item.status_title)?_c('span',[_vm._v(" "+_vm._s(item.status_title)+" ")]):_vm._e()]}},{key:"cell(deadline)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deadline)+" ")]}},{key:"cell(paragraph)",fn:function(){return [_vm._v(" "+_vm._s(_vm.datas.paragraph)+" ")]},proxy:true},{key:"cell(ministry_name)",fn:function(ref){
var item = ref.item;
return [(item.management)?_c('span',[_vm._v(" "+_vm._s(item.management_type.name)+" "),_c('br'),_vm._v(" "+_vm._s(item.management.name)+", "+_vm._s(item.management.user.lastname)+" "),_c('br'),_vm._v(_vm._s(item.management.user.firstname)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.ministry_name)+" ")])]}},{key:"cell(author)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.author.firstname)+" "+_vm._s(item.author.lastname)+" ")]}},{key:"cell(updated_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }