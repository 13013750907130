import request from '@/libs/axios'

// API CATEGORY
export function getCategories(params) {
  return request({
    url: '/api/documents/categories',
    method: 'get',
    params,
  })
}
export function addCategories(data) {
  return request({
    url: '/api/documents/categories',
    method: 'post',
    data,
  })
}
export function editCategory(data) {
  return request({
    url: `/api/documents/categories/${data.get('id')}`,
    method: 'post',
    data,
  })
}
export function deleteCategory(data) {
  return request({
    url: `/api/documents/categories/${data.id}`,
    method: 'delete',
  })
}
export function getCategory(data) {
  return request({
    url: `/api/documents/categories/${data.id}`,
    method: 'get',
    data,
  })
}

// API DOCUMENTS
export function getDocs(params) {
  return request({
    url: '/api/documents/docs',
    method: 'get',
    params,
  })
}
export function addDoc(data) {
  return request({
    url: '/api/documents/docs',
    method: 'post',
    data,
  })
}
export function editDoc(data) {
  return request({
    url: `/api/documents/docs/${data.get('id')}`,
    method: 'post',
    data,
  })
}
export function deleteDoc(data) {
  return request({
    url: `/api/documents/docs/${data.id}`,
    method: 'delete',
  })
}

export function getDoc(data) {
  return request({
    url: `/api/documents/docs/${data.id}`,
    method: 'get',
  });
}

// API PARAGRAPH
export function getParagraphs(params) {
  return request({
    url: '/api/documents/paragraphs',
    method: 'get',
    params,
  })
}
export function getParagraph(id) {
  return request({
    url: `/api/documents/paragraphs/${id.id}`,
    method: 'get',
  })
}
export function addParagraphs(data) {
  return request({
    url: '/api/documents/paragraphs',
    method: 'post',
    data,
  })
}
export function editParagraph(data) {
  return request({
    url: `/api/documents/paragraphs/${data.get('id')}`,
    method: 'post',
    data,
  })
}
export function deleteParagraph(data) {
  return request({
    url: `/api/documents/paragraphs/${data.id}`,
    method: 'delete',
  })
}

// paragraph history

export function getParagraphHistory(params) {
  return request({
    url: '/api/documents/paragraph-history',
    method: 'get',
    params,
  })
}

export function storeDocumentHistory(data) {
  return request({
    url: '/api/documents/histories',
    method: 'post',
    data,
  })
}

export function getDocumentHistory(data) {
  return request({
    url: `/api/documents/histories/${data.id}`,
    method: 'get',
  })
}

export function updateDocumentHistory(data) {
  return request({
    url: `/api/documents/histories/${data.get('id')}`,
    method: 'post',
    data,
  })
}

// my paragraphs
export function getMyParagraphs(params) {
  return request({
    url: '/api/documents/get-my-paragraphs',
    method: 'get',
    params,
  })
}
