export const listTemplate = {
  data: () => ({
    id: null,
    datas: [],
    filters: {
      search: '',
    },
    pagination: {
      page: 1,
      pageSize: 10,
    },
    total: 0,
    modalVisible: false,
    modalTitle: '',
  }),
  computed: {
    params() {
      const params = { ...this.pagination, ...this.filters }

      return params
    },
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      this.modalVisible = false
      this.$store.dispatch('settings/setLoading', true)

      this.actions.get(this.params).then(({ data }) => {
        if (data.data) {
          if (data.data.data) {
            this.datas = data.data.data
          } else {
            this.datas = data.data
          }
          if (data.data.total) {
            this.total = data.data.total
          }
        } else {
          this.datas = data.data
        }

        if (data.total) {
          this.total = data.total
          this.pagination.page = data.page
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => this.$store.dispatch('settings/setLoading', false))
    },

    handleAdd() {
      this.id = null
      this.modalVisible = true
    },
    handleEdit(id) {
      this.id = id
      this.modalVisible = true
    },
    handleDelete(id) {
      if (id) {
        this.$swal({
          title: this.$t('areYouSure'),
          text: this.$t('wontReturn'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('YesDelete'),
          cancelButtonText: this.$t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
        }).then(result => {
          if (result.value) {
            this.actions.delete({ id }).then(res => {
              this.$swal({
                icon: 'success',
                title: this.$t('Deleted'),
                text: this.$t('dataHasDeleted'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
              this.fetchList()
            })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: this.$t('Cancelled'),
              text: this.$t('yrDataSafe'),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
      }
    },
  },
  watch: {
    'pagination.page': {
      immediate: true,
      handler(val) {
        // this.fetchList();
      },
    },
  },
}
