<template>
  <section class="paragraph-history">
    <b-table :items="[datas]" :fields="fields"
             striped
             hover
             responsive>
      <template #cell(id)="data">
        <span class="text-md text-primary"> {{ (data.index +1) }}</span>
      </template>
      <template #cell(status)="{item}">
        {{item.active_history.status.name}}
        <br>
        <span v-if="item.active_history.status_title">
        {{item.active_history.status_title}}
      </span>
      </template>
      <template #cell(ministry_name)="{item}">
      <span v-if="item.active_history.management">
        {{item.active_history.management_type.name}} <br> {{item.active_history.management.name}},
        <span v-if="item.active_history.management.user">
          {{item.active_history.management.user.lastname}} {{item.active_history.management.user.firstname}}
        </span>
      </span>
        <span v-else>
        {{item.active_history.ministry_name}}
      </span>
      </template>
      <template #cell(deadline)="{item}">
        {{ item.active_history.deadline }}
      </template>

    </b-table>
    <div class="my-4">
      <h3 ><strong>
        {{$t('History')}}
      </strong></h3>
    </div>

    <b-table :items="datas.histories" :fields="hisotryItems"
             striped
             hover
             responsive>
      <template #cell(id)="data">
        <span class="text-md text-primary"> {{ (data.index +1) }}</span>
      </template>
      <template #cell(status)="{item}">
        {{item.status.name}}
        <br>
        <span v-if="item.status_title">
        {{item.status_title}}
      </span>
      </template>
      <template #cell(deadline)="{item}">
        {{ item.deadline }}
      </template>
      <template #cell(paragraph)>
        {{datas.paragraph}}
      </template>
      <template #cell(ministry_name)="{item}">
            <span v-if="item.management">
              {{item.management_type.name}} <br> {{item.management.name}},
              {{item.management.user.lastname}} <br>{{item.management.user.firstname}}
            </span>
        <span v-else>
              {{item.ministry_name}}
            </span>
      </template>
      <template #cell(author)="{item}">
        {{item.author.firstname}} {{item.author.lastname}}
      </template>
      <template #cell(updated_date)="{item}">
        {{item.updated_at}}
      </template>

    </b-table>

  </section>
</template>

<script>
import { listTemplate } from '@/utils/mixins/listTemplate'
import { getParagraphHistory } from '@/api/documents'

const actions = {
  get: getParagraphHistory,
}

export default {
  name: 'ParagraphHistory',
  mixins: [
    listTemplate,
  ],
  data() {
    return {
      actions,
      filters: {
        document_id: this.$route.params.id,
        paragraph_id: this.$route.params.paragraphId,
      },
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'paragraph',
          label: this.$t('Paragraph'),
        },
        {
          key: 'ministry_name',
          label: this.$t('Ministry_name'),
        },
        {
          key: 'deadline',
          label: this.$t('Deadline'),
        },
        {
          key: 'status',
          label: this.$t('Status_id'),
        },
      ],
      hisotryItems: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'paragraph',
          label: this.$t('Paragraph'),
        },
        {
          key: 'author',
          label: this.$t('Author'),
        },
        {
          key: 'status',
          label: this.$t('Status_id'),
        },
        {
          key: 'updated_date',
          label: this.$t('Updated_date'),
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
